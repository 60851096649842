import { Appearance } from '../../../../../components/appearance';
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function TrevantiDemilitarizedCorridor() {
    return {
        "date": <span>1266</span>,
        "type": <span>Territory</span>,
        "owned": <span>none</span>,
        "region": <span><TextInlineLink name={"bs_space_calliara_space"} /></span>,
        "sector": <span>Magellanic</span>,
        "capital": <span><TextInlineLink name={"bs_planet_thalassa"} /></span>,
        "people": <span>Trivantians (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className="card-text text-justify">
                    The <span className="strong">Trevanti Demilitarized Corridor (TDC)</span> is a politically neutral region within the Magellanic Sector, specifically designated as a conflict resolution zone for the galaxy’s dominant powers. Established 1,266 years ago in the aftermath of several devastating wars, it has since served as both a buffer and a diplomatic stage for rival factions seeking to avoid full-scale war. Its strategic location on the border between Calliara Space and Old Space makes it a pivotal region for trade, negotiation, and—occasionally—covert operations.
                </p>
                <p className="card-text text-justify">
                    The TDC’s foundation stemmed from escalating tensions during early centuries of High Light Age, when competing powers vied for dominance over the galaxy’s most resource-rich sectors. The corridor was originally envisioned as neutral ground, overseen by an autonomous council of mediators known as the Trevanti Council. These mediators, drawn from all factions, ensured the enforcement of non-aggression agreements. The corridor’s creation marked a rare moment of cooperation between the Great Monarchy of Earth, the Eiliree Domination, and many Calliaran Coalitions.
                </p>
                <h4 className="title-bg">Structure and Governance</h4>
                <p className="card-text text-justify">
                    The TDC spans a series of systems designated as off-limits to military installations and aggressive posturing. While officially neutral, its governance has evolved over the centuries, with the Trevanti Council functioning as a de facto government for the region. The council includes representatives from major powers and select independent worlds, ensuring that no single faction dominates decision-making.
                </p>
                <p className="card-text text-justify">
                    Key stations within the corridor, like Station Yarelle and Arelis Crosspoint, act as hubs for diplomacy and trade. While warships are strictly prohibited, policing fleets maintain order and ensure adherence to the corridor’s treaties.
                </p>
                <h4 className="title-bg">Economy and Trade</h4>
                <p className="card-text text-justify">
                    Due to its demilitarized status, the TDC has become a bustling trade route, particularly along the <span className="strong">Thalassa Hyperlane</span> which is a part of larger Earth - Agathon - Ternerian run Hyperrun, which connects Old Space to Calliara Space. Thalassa itself is one of the corridor’s most significant settlements, serving as a key point of commerce and cultural exchange. Merchants, diplomats, and adventurers frequent the TDC for its relatively stable environment, though its neutrality often attracts less scrupulous elements as well.
                </p>
                <h4 className="title-bg">Challenges and Controversies</h4>
                <p className="card-text text-justify">
                    <span className="strong">Galactic War Impact</span>: The TDC’s neutrality was tested during the Galactic War, as both sides often skirted its borders to stage covert operations. While the corridor avoided outright destruction, trust in its impartiality suffered.
                    <span className="strong">Shadow Influence</span>: Despite its neutrality, the corridor is rife with espionage. Powers use the TDC to exchange secrets, test political waters, and negotiate backdoor deals.
                    <span className="strong">Rogue Systems</span>: A few smaller factions and rogue planets within the corridor have resisted the Trevanti Council’s authority, occasionally causing instability.
                </p>
            </>
    }
}
