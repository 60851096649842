import { TextInlineLink, TextPopup } from "../../../components/cms/wiki_functions";
import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { Timeline } from 'antd';

export function BlackSunTimelinePage() {
    document.title = "World history || Cydonian girl & Black sun"
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook
                    name={<span><i className="fa-solid fa-clock-rotate-left"></i>  World history</span>} />
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <h2 className="title-bg">World history</h2>
                    </div>
                    <section className="resume">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5 id={"high-light-travel-times"} className="title-bg">
                                        High <TextInlineLink
                                            name={"bs_timeline_light_travel_times"} text={"Light Age"} />
                                        <small>~2800 -
                                            0 years before <TextInlineLink name={"book_tamanrasett_contact"}
                                                text={"Tamanrasett"} />
                                        </small>
                                    </h5>
                                    <blockquote>
                                        <p className="card-text text-justify">
                                            <i>BT</i> and <i>AT</i>. <i>After Tamanrassett</i> and <i>Before Tamanrassett</i>... Why is the
                                            history of mankind divided into two unequal parts? And what is this
                                            Tamanrasett? Or who? Let's start with the most important thing: Tamanrasett
                                            was (and still is) a planet deep in the Ternerian end. And the events that
                                            once happened in its orbit divided our world into before and after. One
                                            second you're living in a plain and simple galaxy, and the next... well, you
                                            know what galaxy we live in now. Is it better? Perhaps I am about 700 years
                                            too late with this question.
                                        </p>
                                        <small>From lectures by history professor Miriam Tarson, 839 AT</small>
                                    </blockquote>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>11.5 months</strong>,
                                                children: (
                                                    <>
                                                        <p>Current moment (<TextInlineLink
                                                            name={"book_cydonian_autumn"} text={"Cydonian autumn"} />,
                                                            Part I: Day
                                                            of All Bombs)</p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">14 - 11.5 months <TextInlineLink
                                        name={"book_tamanrasett_contact"} text={"BT"} /> <small>(Revolution
                                            in The
                                            Monarchy, <TextInlineLink
                                                name={"book_cydonian_girl"} />, <TextInlineLink
                                                name={"book_hyperbeacons"} />)</small></h6>

                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>11.5 months:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-lock"></i>,
                                                children: (
                                                    <>
                                                        <p>Tribunal sends <TextInlineLink
                                                            name={"bs_char_esora_eve"} text={"Eve Esora"} /> to hard
                                                            labor
                                                            on <TextInlineLink name={"bs_planet_alkiviad"} /></p>
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>12 months:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <p>The first
                                                            officially <TextInlineLink
                                                                name={"bs_timeline_tajbennae_incident"}
                                                                text={"confirmed contact"} /> between humans
                                                            (<TextInlineLink
                                                                name={"bs_spec_humans"} text={"Homo sapiens"} />) and
                                                            vitruvians <TextInlineLink name={"bs_spec_vitruvianus"}
                                                                text={"(Homo vitruvianus)"} /> (<TextInlineLink
                                                                name={"book_hyperbeacons"} />).<br /><TextInlineLink
                                                                name={"bs_char_the_whip"} /> gains access
                                                            to <TextInlineLink
                                                                name={"bs_tech_northern_star_of_niradanhae"} /> and finds
                                                            a new ally
                                                            in the fight against the <TextInlineLink
                                                                name={"bs_char_shimmering_devil"}
                                                                text={"Shimmering Devil"} /></p>
                                                        <p>
                                                            Recognition of Mihelm's
                                                            independence.
                                                            End of the <TextInlineLink
                                                                name={"bs_timeline_the_shark_revolt"}
                                                                text={"Revolution in The Monarchy"} /> (<TextInlineLink
                                                                name={"book_cydonian_girl"} />)
                                                        </p>
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>13 months:</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>Mass disappearance of the
                                                        inhabitants
                                                        of <TextInlineLink name={"bs_planet_exile_1"}
                                                            text={"Last Exodus"} /> (<TextInlineLink
                                                            name={"book_hyperbeacons"} />)
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>14 months:</strong>,
                                                children: (
                                                    <>
                                                        <p>
                                                            Beginning of the <TextInlineLink
                                                                name={"bs_timeline_the_shark_revolt"}
                                                                text={"Revolution in The Monarchy"} /> (<TextInlineLink
                                                                name={"book_cydonian_girl"} />)
                                                        </p>
                                                        <p>
                                                            <TextInlineLink
                                                                name={"bs_char_de_levine_rosalie"}
                                                                text={"Rosalie"} /> meets <TextInlineLink
                                                                name={"bs_char_de_karma_ivar"} text={"De Karma"} />
                                                        </p>
                                                    </>
                                                )
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">25 years - 14 months <TextInlineLink
                                        name={"bs_timeline"}
                                        text={"BT"} /></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>3 years</strong>,
                                                dot: <i className="fas fa-book"></i>,
                                                color: 'grey',
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_ursula_adams"} /> completes the 4th edition
                                                        of <i><TextInlineLink
                                                            name={"bs_universe_bird_of_passage_guide_to_milky_way"}
                                                            text={"Birds of passage guide to Milky way"} /></i>
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>7.5 years</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"}
                                                            text={"Ivar"} /> and <TextInlineLink
                                                            name={"bs_char_browdy_adam"}
                                                            text={"Adam"} /> are joined by <TextInlineLink
                                                            name={"bs_char_can_beucan_gaereas"} text={"Gary Baukan"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>10 years</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} /> hires <TextInlineLink
                                                            name={"bs_char_browdy_adam"} text={"Adam Browdy"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>11 years</strong>,
                                                color: 'grey',
                                                dot: <i className="fas fa-book"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_ursula_adams"} /> releases the first version
                                                        of <TextInlineLink
                                                            name={"bs_universe_bird_of_passage_guide_to_milky_way"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>13 years</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_planet_tiora"}
                                                            text={"Tiorian Incident"} />, <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} /> is dishonorably discharged
                                                        from
                                                        Cydonian Battlenavy. <br />He becomes a freelancer
                                                        in <TextInlineLink
                                                            name={"bs_char_lenisaad_victor"}
                                                            text={"Victor Lenisaad's"} /> crew,
                                                        thereby joining the <TextInlineLink
                                                            name={"bs_comp_free_trade_league"}
                                                            text={"Free Traders"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>14 years</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} /> is one of the youngest
                                                        admirals in
                                                        Cydonian Battlenavy
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>19 years</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_van_gloria_adel"} /> elected
                                                        Queen of Priority after <TextPopup
                                                            name={"bs_char_ivetta_sheridan"}
                                                            text={"Yvette Sheridan's"} /> removal
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>19 years</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_state_cydonian_priority"}
                                                            text={"Priority"} /> and <TextInlineLink
                                                            name={"bs_comp_feathered_serpent"}
                                                            text={"Feathered Serpent"} /> war over <TextInlineLink
                                                            name={"bs_space_samborian_traverse"}
                                                            text={"Samborian Traverse"} />
                                                    </>
                                                )
                                            },
                                            {
                                                label: <strong>24 years</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_can_beucan_gaereas"}
                                                            text={"Gary Baukan"} /> joins <TextInlineLink
                                                            name={"bs_comp_bonecollectors"} text={"Bonecollectors"} />
                                                    </>
                                                )
                                            },
                                        ]}
                                    />

                                    <h6 className="title-bg">37 - 25 years <TextInlineLink name={"bs_timeline"}
                                        text={"BT"} /> - <TextInlineLink
                                            name={"bs_timeline_galactic_war"}
                                            text={"First Galactic War"} /></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>25 years</strong>,
                                                color: 'green',
                                                children: (
                                                    <>
                                                        <p>End of the <TextInlineLink
                                                            name={"bs_timeline_galactic_war"}
                                                            text={"(First) Galactic War"} /></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>26 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <p><TextInlineLink
                                                            name={"bs_char_de_levine_rosalie"} /> is born</p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>26 years</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <p><i><TextInlineLink
                                                            name={"bs_ships_nyorun_dreams"}
                                                            text={"Njörun's Dreams"} /></i> destroys biosphere
                                                            of <TextInlineLink
                                                                name={"bs_planet_esghariot_en_nuvnish"}
                                                                text={"Dead Esghariot"} /></p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>38 years</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <p>Beginning of the <TextInlineLink
                                                            name={"bs_timeline_galactic_war"}
                                                            text={"(First) Galactic War"} /></p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />
                                    <h6 className="title-bg">~2800 - 37 years <TextInlineLink name={"bs_timeline"}
                                        text={"BT"} /></h6>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>41 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_esora_eve"} /> is born

                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>46 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_browdy_adam"} /> is born

                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>54 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_can_beucan_gaereas"} /> is born
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>60 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_skarsgard_vivien"} /> is born
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>62 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_skarsgard_oliver"} /> is born
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>68 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_ursula_adams"} /> is born
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>71 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_van_gloria_adel"} /> is born
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>73 years</strong>,
                                                color: 'purple',
                                                dot: <i className="fa-solid fa-user"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_char_de_karma_ivar"} /> is born
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>1200 years</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_planet_avvaline"} /> is inhabited
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>1266 years</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe"></i>,
                                                children: (
                                                    <>
                                                        <p>
                                                            <TextInlineLink
                                                                name={"bs_space_trevanti_demilitarized_corridor"} /> established as the place to resolve conflicts between galaxy's greatest powers
                                                        </p>
                                                        <p>
                                                            <TextInlineLink
                                                                name={"bs_planet_thalassa"} /> was settled
                                                        </p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>1823 years</strong>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_timeline_ackron_conspiracy"}
                                                            text={"Ackron conspiracy"} /> brought the Yaveth dynasty to power
                                                        in <TextInlineLink
                                                            name={"bs_state_ajliraenian_domination"} text={"Domination"} />
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~2000-1700 years</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <TextInlineLink
                                                            name={"bs_timeline_war_300"} text={"Tricentennial War"} /> between the
                                                        earthlings and the cydonians
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~2100 years</strong>,
                                                children: (
                                                    <>
                                                        First mentions of <TextInlineLink
                                                            name={"bs_planet_arville"} text={"Arville"} /> in <TextInlineLink
                                                            name={"bs_people_alamarsie"} text={"alamarsie"} /> <TextInlineLink
                                                            name={"bs_tech_hiveship"} text={"hiveship"} /> logs
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~2800 years</strong>,
                                                children: (
                                                    <>
                                                        First documented
                                                        contact of <TextInlineLink name={"bs_people_alamarsie"}
                                                            text={"alamarsie"} /> and <TextInlineLink
                                                            name={"bs_char_the_whip"} />
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />
                                    <h5 id={"light-era-dawn"} className="title-bg">Dawn of the Light Age <small>~5500 –
                                        ~2800 years before <TextInlineLink name={"book_tamanrasett_contact"}
                                            text={"Tamanrasett"} /></small></h5>

                                    <div className="">
                                        <h6 id={"late-colonies-time"} className="title-bg">~4800 – ~2800 years
                                            before <TextInlineLink name={"book_tamanrasett_contact"}
                                                text={"Tamanrasett"} /> - Late colonial time</h6>
                                        <blockquote><p className="card-text text-justify">
                                            To call a long-gone era LATE colonial time is sheer madness. After
                                            all, what then would you call a planet inhabited yesterday? A super-late
                                            colony? Of course, I know what they mean, because colonies are faraway
                                            worlds that are hard to reach. In our time, and in the High Era, such a
                                            problem no longer existed. Congratulations, now you understand the meaning
                                            of the name. It doesn't make it any less strange, though, does it?
                                        </p><small>From lectures by history professor Miriam Tarson, 887 AT</small>
                                        </blockquote>
                                        <Timeline
                                            mode="left"
                                            items={[
                                                {
                                                    label: <strong>~3600 years</strong>,
                                                    children: (
                                                        <>
                                                            <p>Automated terraformers began
                                                                transforming <TextInlineLink name={"bs_planet_tamanrasett"}
                                                                    text={"Tamanrasett"} /> into a planet with
                                                                an earth-like biosphere</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~3800 years</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>
                                                            <p>Earth colonists established an independent
                                                                settlement on <TextInlineLink name={"bs_planet_agathon"}
                                                                    text={"Agathon"} /></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4000 years</strong>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_alkanarra_tristan"}
                                                                text={"Tristan the Conqueror"} /> united most of the Earth colonies
                                                                in <TextInlineLink name={"bs_space_the_old_space"}
                                                                    text={"Old Space"} /> into the first <TextInlineLink
                                                                    name={"bs_state_the_great_monarchy"}
                                                                    text={"full-fledged interstellar state"} />. After spending a
                                                                lifetime doing this, he died at the age of nearly 200. His descendants
                                                                gain unlimited power over Earth in a civil war. The transformation of
                                                                Earth's relatively liberal society into an oppressive state, later known
                                                                as the Great Monarchy, begins
                                                            </p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4500 years</strong>,
                                                    children: (
                                                        <>
                                                            <p>The first prototype of the modern
                                                                (non-Arthurian) <TextInlineLink name={"bs_tech_grn"}
                                                                    text={"InterNetwork"} /> is invented,
                                                                although full galactic communications are still a long way off</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4730 years</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_planet_helena"} /> is populated
                                                            </p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4750 years</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_planet_ajliraen"} /> is populated</p>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>

                                    <div className="">
                                        <h6 id={"first-colonies-time"} className="title-bg">~5500 – ~4800 years
                                            before <TextInlineLink name={"book_tamanrasett_contact"}
                                                text={"Tamanrasett"} /> - The First Colonies Time</h6>
                                        <blockquote><p className="card-text text-justify">
                                            The time of the first colonies
                                            This period of history begins with the invention of the first stable light
                                            engine. Mankind enters an era of active expansion, but technology is still
                                            too imperfect and the settlement of new worlds is extremely slow, often
                                            tragic. The end of the First Colonies Time is considered to be the
                                            settlement of Cydonia: at this point the expansion reached the scale and
                                            speed that allowed Homo sapiens to begin the true conquest of the galaxy.
                                        </p><small>Julian Harari, <i>Homo sapiens: The True Story of the People from Old
                                            Earth, 265 AT</i></small></blockquote>
                                        <Timeline
                                            mode="left"
                                            items={[
                                                {
                                                    label: <strong>~4800 years</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_brienne_de_estrada"} /> brought a tattered colonization
                                                                ship to Cydonia, but the first attempt to build an outpost killed nearly
                                                                the entire population. Newly minted cydonians ask for help
                                                                from <TextInlineLink name={"bs_people_alamarsie"}
                                                                    text={"alamarsie"} /></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~4900 years</strong>,
                                                    children: (
                                                        <>
                                                            <p><TextInlineLink
                                                                name={"bs_char_arthur_the_founder"}
                                                                text={"Arthur the Founder"} /> unites <TextInlineLink
                                                                    name={"bs_people_alamarsie"} text={"alamarsie"} /> and soon disappears
                                                                under mysterious circumstances</p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~5100 years</strong>,
                                                    color: 'green',
                                                    dot: <i className="fa-solid fa-globe"></i>,
                                                    children: (
                                                        <>

                                                            <p>Unknown settlers make their way at
                                                                sub-light speed from <TextInlineLink name={"bs_planet_earth"}
                                                                    text={"Earth"} /> to <TextInlineLink
                                                                    name={"bs_planet_esghariot_en_nuvnish"}
                                                                    text={"Esghariot-en-Nouvnish"} />,
                                                                founding the <TextInlineLink name={"bs_space_breeze_sector"}
                                                                    text={"Breeze Colony"} /></p>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    label: <strong>~5500 years</strong>,
                                                    children: (
                                                        <>
                                                            <p>The first <TextInlineLink
                                                                name={"bs_tech_engine_overlight"}
                                                                text={"light engine"} /> is supposedly invented, the approximate
                                                                beginning of the <TextInlineLink name={"bs_timeline_light_travel_times"}
                                                                    text={"Age of FTL travel"} /></p>
                                                        </>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                    <h5 id={"underlight-age"} className="title-bg">Underlight age <small>0 - ~5500 years
                                        before <TextInlineLink name={"book_tamanrasett_contact"}
                                            text={"Tamanrasett"} /></small></h5>
                                    <blockquote><p className="card-text text-justify">
                                        The Underlight age is a long time span. It encompasses everything that
                                        happened
                                        on Old Earth from the emergence of humans as a species to the day when the first
                                        man risked everything and accelerated faster than light. Truly, it was an era of
                                        madmen and daredevils, ready to do anything for the sake of discovering new
                                        horizons. It is a pity that much of the knowledge about the events of those
                                        times has been lost forever in the flames of the many cataclysms experienced by
                                        our homeworld.
                                    </p><small>Julian Harari, <i>Homo sapiens: The True Story of the People from Old
                                        Earth, 265 AT</i></small></blockquote>
                                    <Timeline
                                        mode="left"
                                        items={[
                                            {
                                                label: <strong>~5600 years</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe"></i>,
                                                children: (
                                                    <>
                                                        <p>Settlement on <TextInlineLink
                                                            name={"bs_planet_michelm"} /> is founded</p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~5800 years</strong>,
                                                color: 'green',
                                                dot: <i className="fa-solid fa-globe"></i>,
                                                children: (
                                                    <>
                                                        <p>Settlers from <TextInlineLink
                                                            name={"bs_planet_earth"} text={"Earth"} /> established a colony in
                                                            the <TextInlineLink name={"bs_planet_sleeping_suns_maison"}
                                                                text={"Sleeping Suns Manor"} />, on New Patagonia and
                                                            Ellata</p>
                                                    </>
                                                ),
                                            },
                                            {
                                                label: <strong>~6000 years</strong>,
                                                color: 'red',
                                                dot: <i className="fa-solid fa-bolt"></i>,
                                                children: (
                                                    <>
                                                        <p>Someone nicknamed the <TextInlineLink
                                                            name={"bs_char_shimmering_devil"} /> destroys <TextInlineLink
                                                                name={"bs_char_the_whip"} text={"The Whip's"} /> civilization with
                                                            the <TextInlineLink
                                                                name={"bs_weaponry_the_black_sun"} text={"Black Sun"} />. The Sun
                                                            itself falls into Whip's hands</p>
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


        </div>
    )
}
