import wiki from './cms/wiki.json';
import imgs from './cms/images_repo.json';

export function BooksBlacksunPart1() {
    return (
        <>
            <div className="row row-alt">
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_cydonian_girl"].link}>Cydonian girl</a><br />10.03.2021
                    </h5>
                    <a href={wiki["book_cydonian_girl"].link}>
                        <div className="img-thumbnail-v2" style={{ "maxWidth": "210px", "margin": "auto" }}>
                            <img style={{ "maxWidth": "100%" }} alt="Cover of Cydonian girl"
                                src={imgs["book_cydonian_girl_200_286_en"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_hyperbeacons"].link}>Beacon thieves</a><br />09.09.2021
                    </h5>
                    <a href={wiki["book_hyperbeacons"].link}>
                        <div className="img-thumbnail-v2" style={{ "maxWidth": "210px", "margin": "auto" }}>
                            <img style={{ "maxWidth": "100%" }} alt="Cover of Beacon thieves"
                                src={imgs["book_hyperbeacons_200_286_en"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_cydonian_autumn"].link}>Cydonian autumn</a><br />~2025
                    </h5>
                    <a href={wiki["book_cydonian_autumn"].link}>
                        <div className="img-thumbnail-v2" style={{ "maxWidth": "210px", "margin": "auto" }}>
                            <img style={{ "maxWidth": "100%" }} alt="Cover of Cydonian autumn"
                                src={imgs["book_cydonian_autumn_200_286_en"].link} />
                        </div>
                    </a>
                </div>
                <div className="text-center col-lg-3 book-item">
                    <h5 className="ordinary"><a href={wiki["book_bonecollectors"].link}>Bonecollectors</a><br />~2026
                    </h5>
                    <a href={wiki["book_bonecollectors"].link}>
                        <div className="img-thumbnail-v2" style={{ "maxWidth": "210px", "margin": "auto" }}>
                            <img style={{ "maxWidth": "100%" }} alt="Cover of Bonecollectors"
                                src={imgs["book_bonecollectors_200_286_en"].link} />
                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}