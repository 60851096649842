import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Brienne_cluster() {
    return {
        "date": <span>-</span>,
        "type": <span>galaxy region</span>,
        "owned": "-",

        "capital": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink name={"bs_people_ajliree"} />, <TextInlineLink name={"bs_people_samborians"} /></span>,
        "bs_universe_currencies": <span><TextInlineLink name={"bs_currency_charmo"} text={"Cydonian charm"} />, <TextInlineLink
            name={"bs_currency_dalmatia"} text={"Dalmatia"} /></span>,
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className='card-text text-justify'>
                    The <span className="strong">Brianne Cluster</span> is a sprawling region of inhabited space, an intricate patchwork of nations, factions, and independent worlds situated across the vast expanse beyond The Rift. This distant region, positioned far from the old Monarchy’s reach, serves as a playground for power struggles, trade, and cultural exchange. Within the Cluster lie some of the galaxy’s most influential entities: the cosmopolitan Cydonian Priority, the enigmatic Eilirén Domination, and the loosely aligned Free Worlds—alongside hundreds of smaller planet-states, each with its unique story.
                </p>
                <p className='card-text text-justify'>
                    Named after <TextInlineLink name={"bs_char_brienne_de_estrada"} text={"Brianna De Estrada"} />, a historical pioneer of the Dawn of the Light Age, the Cluster reflects the spirit of her legacy: resilience, ingenuity, and daring exploration. Though Brianna was not a pilot, she earned the honor of leading the first generational ship to Cydonia through sheer determination, bravery, and an infamous sacrifice along the perilous voyage.
                </p>
                <h4 className="title-bg">Society and Economy</h4>
                <p className='card-text text-justify'>
                    The heart of the Cluster is Cydonia, capital of the Cydonian Priority, whose currency, the Cydonian Charm, serves as the de facto economic backbone of the region. Whether planets belong to formal alliances or remain independent, most transactions—legal or otherwise—depend on the stability of the Charm. However, on the outer fringes, another currency, the Dalmatia, circulates within pirate networks, black markets, and criminal syndicates. The fluidity between these economic systems adds both opportunity and risk, blurring the lines between legitimate business and piracy.
                </p>
                <p className='card-text text-justify'>
                    The Priority’s influence is vast, and Cydonian ideals—liberal governance, meritocracy, and scientific progress—have permeated deeply into neighboring territories. Yet the cosmopolitan allure of the Cydonians is balanced by the distinctiveness of their neighbors. Among these, the Eiliréen Domination cultivates an ancient aristocratic tradition under the Yaveth dynasty, while the scattered Free Worlds embody a patchwork of independent spirit and chaos, home to rebels, opportunists, and exiles.
                </p>
                <p className='card-text text-justify'>
                    Life in the Cluster is vibrant but perilous: piracy and smuggling are persistent threats, especially in the Cluster’s outer regions, where the thin reach of centralized power creates opportunities for rogue factions. Here, Priority-flagged trade vessels often share space with Free World freighters and outlaw pirate ships—alliances and rivalries shifting like the stars themselves.
                </p>
                <h4 className="title-bg">Peoples and Culture</h4>
                <p className='card-text text-justify'>
                    The Brianne Cluster is home to a diverse population that reflects both ancient and modern migrations across the galaxy.
                </p>
                <p className='card-text text-justify'>
                    Cydonians, as the dominant group, embody an eclectic blend of cultures, driven by meritocratic ideals. Their presence spreads not only through formal political means but also through art, science, and commerce. They excel in the arts, with institutions such as the Cydonian Ballet and Cassian opera influencing trends across the galaxy. Many Cydonians are polyglots, fluent in numerous languages, which enables them to integrate seamlessly into different societies.
                </p>
                <p className='card-text text-justify'>
                    The Eilireé, ruled by the aristocratic Yaveth family, prefer tradition, pageantry, and rigid hierarchies. Their long history of self-rule has made them cautious allies of the Priority but also persistent rivals. Their people are deeply spiritual, honoring ancient rituals, which are sometimes seen as superstitious by outsiders.
                </p>
                <p className='card-text text-justify'>
                    Samborians are another prominent group in the Cluster, known for their ingenuity and resourcefulness. They inhabit sparsely populated worlds, often taking on roles as engineers, scavengers, and traders. Samborians are viewed with both respect and suspicion; their knack for surviving in harsh environments gives them an edge in both trade and conflict, but their independence often conflicts with the more structured powers in the Cluster.
                </p>
                <p className='card-text text-justify'>
                    The Cluster’s cultural ethos reflects its complex history of migrations, wars, and alliances. While Cydonians promote scientific advancement and liberal governance, many regions still cling to older traditions, mysticism, and rites passed down from forgotten eras. This clash between the ancient and the modern fuels much of the region’s political intrigue.
                </p>
                <h4 className="title-bg">Piracy</h4>
                <p className='card-text text-justify'>
                    Pirates are as much a part of the Brianne Cluster as its thriving trade routes. While Cydonian patrols attempt to maintain order along major lanes, the vastness of space makes it impossible to secure every sector. The outer fringes—where Priority influence wanes—are notorious for piracy, with captains often forming temporary alliances to seize poorly defended convoys or run smuggling operations.
                </p>
                <p className='card-text text-justify'>
                    Pirate havens flourish on neglected moons and asteroid belts where smugglers exchange weapons, rare goods, and contraband. Some pirates have even risen to the status of legends, their tales inspiring both fear and admiration across the Cluster. However, while most pirates operate independently, some are believed to work in covert arrangements with Free World factions or rogue lords from the Domination.
                </p>
            </>
    }
}

