import { TextInlineLink } from "../../../../../components/cms/wiki_functions";
import { Appearance } from "../../../../../components/appearance";

export function Thalassa() {

    return {
        "date": <span>1266 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span>none, planet-state</span>,
        "habitats": <span>~1 billion</span>,
        "people": <span>Trivantians (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>3</span>,
        "g": <span>2.04 terrestrial</span>,
        "atmosphere": <span className="text-danger">deadly for humans</span>,
        "biosphere": <span>natural, no human invasion</span>,
        "system": <span>Elaraon</span>,
        "sector": <span>Magellanic</span>,
        "type": <span><TextInlineLink name={"bs_universe_planet_types"} text={"ocean planet"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_calliara_space"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_trevanti_demilitarized_corridor"} /></span>,
        "imgs": [],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">Brief</h3>
                <p className="card-text text-justify">
                    <span className="strong">Thalassa</span> is a serene and enigmatic planet nestled at the juncture of Calliara Space and Old Space. It is a world of rare natural beauty, dominated by vast oceans and archipelagos that seem untouched by time. What sets it apart is not just its geography but its role as the headquarters of the Planet Market, an organization that has made this otherwise obscure planet a critical node in galactic commerce.
                </p>
                <p className="card-text text-justify">
                    Thalassa orbits its star with a collection of moons:
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Selan</span>  – A silvery moon, reflecting light with an ethereal glow, symbolizing wisdom and neutrality
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Clythara</span> - A dusky, mineral-rich moon often associated with ancient mining ventures; its shadowed craters are said to resemble a mosaic from orbit
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Eonys</span> - A pale golden moon that captures the imagination with its dynamic tidal effects on Thalassa’s floating seas
                </p>
                <p className="card-text text-justify">
                    The three moons are visible together during Thalassa’s long nights, often referred to in local poetry as the “Triad of Time,” symbolizing the past (Selan), the present (Clythara), and the future (Eonys).
                </p>
                <h4 className="title-bg">Geography and Atmosphere</h4>
                <p className="card-text text-justify">
                    Thalassa’s surface is 90% water, with the remaining landmass comprising lush, uninhabited islands and towering atolls. The skies are perpetually painted in gradients of blue and violet, reflecting the planet’s dense atmosphere enriched with unique gas compositions that give sunsets an almost otherworldly glow. These atmospheric qualities make the planet unsuitable for large-scale settlement, but perfect for creating a tranquil and secure environment.
                </p>
                <p className="card-text text-justify">
                    Orbiting a star on the cooler side of the spectrum (Elaraon is a blue-white giant), Thalassa enjoys a temperate climate with mild variations. Its remote location and unpopulated surface have helped preserve its pristine nature, making it a sanctuary for interstellar elites and diplomats visiting the Planet Market’s floating headquarters.
                </p>
                <h4 className="title-bg">Cultural Significance</h4>
                <p className="card-text text-justify">
                    The planet is shrouded in an aura of mystique. While it is physically remote, Thalassa serves as a gateway between the Old Space powers and the dynamic frontiers of Calliara Space. This positioning, both literal and symbolic, has earned it a reputation as a place where history is quietly shaped.
                </p>
                <p className="card-text text-justify">
                    The Planet Market has gone to great lengths to maintain the planet’s neutrality. Thalassa is surrounded by heavily regulated space, with fleets from various galactic powers patrolling nearby to ensure its safety. The strict enforcement of this neutrality has made the planet a rare example of cooperation among otherwise competing factions.
                </p>
                <h4 className="title-bg">The Floating City</h4>
                <p className="card-text text-justify">
                    The true heart of Thalassa lies not on its surface but above it, in the massive floating city of Paxora. Suspended high in the atmosphere, the city is a marvel of engineering and elegance, blending sleek modernity with classical influences. Its gravity-modulated platforms allow for towering structures, interconnected by aerial trams and glowing walkways.
                </p>
                <p className="card-text text-justify">
                    Visitors describe the city as eerily quiet, with its carefully curated calm reflecting the seriousness of the business conducted there. The air is filled with the scent of filtered ocean breezes, a stark contrast to the bustling noise of most interstellar hubs. Security is paramount; the Planet Market’s council employs advanced surveillance and AI-driven law enforcement to ensure disputes are resolved discreetly and efficiently.
                </p>
                <h4 className="title-bg">Galactic War</h4>
                <p className="card-text text-justify">
                    Thalassa found itself in a precarious position during the Galactic War, a conflict that shattered the power structures of Old Space and devastated large portions of Calliara Space. Though physically unscathed by the war’s ravages, the planet’s strategic location on the border between Old Space and Calliara Space made it a silent observer to the chaos that unfolded around it.
                </p>
                <p className="card-text text-justify">
                    As the Great Monarchy and its ally, the Eiliree Domination, waged war against the Agathon-led coalition of Calliara Space, Thalassa clung tightly to its declared neutrality. The Planet Market’s leadership issued statements reaffirming their commitment to providing a safe and neutral ground for negotiations and trade, even as interstellar fleets clashed mere light-years away. However, neutrality did not mean inaction.
                </p>
                <p className="card-text text-justify">
                    Behind the scenes, Thalassa became an unspoken gathering point for covert diplomacy. Representatives from both sides of the war occasionally visited the floating city to negotiate prisoner exchanges, supply deals, or even ceasefires in contested sectors. The planet’s neutral status made it a rare common ground, albeit one shrouded in secrecy. High-ranking envoys would arrive in unmarked vessels, their presence barely acknowledged by the Planet Market’s council to preserve anonymity.
                </p>
                <p className="card-text text-justify">
                    Rumors also abound that Thalassa’s databanks hold records of agreements and betrayals that shaped the war’s outcome. Allegations persist that certain factions used the Planet Market’s financial services to launder funds or secure hidden deals, ensuring that even in chaos, commerce continued.
                </p>
                <h4 className="title-bg">Economic Resilience Amidst Conflict</h4>
                <p className="card-text text-justify">
                    The war disrupted trade routes across the galaxy, yet the Planet Market maintained a surprising level of activity. With the collapse of the Great Monarchy’s economic systems and the resource strain on Agathon-led forces, Thalassa adapted by facilitating alternative methods of exchange, including bartering raw materials, rare elements, and even unclaimed celestial bodies.
                </p>
                <p className="card-text text-justify">
                    Its neutrality attracted those desperate to escape the war’s reach—refugees, rogue traders, and even displaced nobles from the Great Monarchy found temporary sanctuary in the planet’s floating city. However, this influx also created tensions, as accusations of harboring spies or profiteers occasionally surfaced.
                </p>
                <h4 className="title-bg">After the War</h4>
                <p className="card-text text-justify">
                    When the dust settled and the Galactic War finally ended, Thalassa emerged as one of the few constants in a galaxy scarred by conflict. Its role as a mediator and facilitator of behind-the-scenes agreements cemented its reputation as a key player in galactic diplomacy, even if much of its influence remained unspoken.
                </p>
                <p className="card-text text-justify">
                    For many, Thalassa symbolizes the endurance of commerce and negotiation amidst chaos. Yet, the scars of its silent complicity linger, with some viewing it as a profiteer of war rather than a neutral arbiter. To this day, the planet remains a subject of debate: was it a sanctuary for peace or simply another cog in the machine of galactic power struggles?
                </p>
            </>
    }
}
