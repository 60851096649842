import { Component } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"
import { MainMenu } from "./components/MainMenu"
import { Home } from "./pages/Home"
import { Books } from "./pages/Books"
import { NotFound } from "./pages/NotFound"
import { Search } from "./pages/search"
import { PageInConstruction } from "./pages/_pageInConstruction"
import wiki from './components/cms/wiki.json';
import { Series_black_sun } from "./pages/series/black_sun/Black_sun"
import { BookCydonianGirl } from "./pages/series/black_sun/Book_1_Cydonian_girl"
import { BookHyperbeacons } from "./pages/series/black_sun/Book_2_Hyperbeacons"
import { BookCydonianAutumn } from "./pages/series/black_sun/Book_3_Cydonian_autumn"
import { BookBonecollectors } from "./pages/series/black_sun/Book_4_Bonecollectors"
import { BookHelleonCold } from "./pages/series/black_sun/Book_5_Helleon_cold"
import { BookTamanrasettContact } from "./pages/series/black_sun/Book_6_Tamanrasett_contact"
import { BookFreeTraders } from "./pages/series/black_sun/Book_7_Free_traders"
import { BookBlackSun } from "./pages/series/black_sun/Book_8_Black_sun"
import { BlackSunWorldMap } from "./pages/series/black_sun/world_map/World_map"
import { BlackSunWorldMapOecumene } from "./pages/series/black_sun/world_map/World_map_oecumene"
import { BlackSunTimelinePage } from "./pages/series/black_sun/BlackSunTimelinePage"
import { War_300 } from "./pages/series/black_sun/glossary/timeline/war_300";
import { The_shark_revolt } from "./pages/series/black_sun/glossary/timeline/the_shark_revolt";
import { Ackron_conspiracy } from "./pages/series/black_sun/glossary/timeline/ackron_conspiracy";
import { Galactic_war } from "./pages/series/black_sun/glossary/timeline/galactic_war";
import { Tajbennae_incident } from "./pages/series/black_sun/glossary/timeline/tajbennae_incident";
import { Light_travel_times } from "./pages/series/black_sun/glossary/timeline/light_travel_times";
import { Unenclosed } from "./pages/series/black_sun/unenclosed/Unenclosed"
import { BSGlossaryRecord } from "./pages/series/black_sun/glossary/GlossaryRecord";
import { GlossaryFullList } from "./pages/series/black_sun/glossary/GlossaryFullList";
import { Tech } from "./pages/series/black_sun/glossary/Tech"
import { Universe } from "./pages/series/black_sun/glossary/Universe"
import { Glossary } from "./pages/series/black_sun/glossary/Glossary"
import { People } from "./pages/series/black_sun/glossary/People"
import { Gallery } from "./pages/series/black_sun/Gallery"
import { Organizations } from "./pages/series/black_sun/glossary/Organizations";
import { Machinery } from "./pages/series/black_sun/glossary/Machinery";
import { Creatures } from "./pages/series/black_sun/glossary/Creatures";
import { Weapons_and_tech } from "./pages/series/black_sun/glossary/Weapons_and_tech"
import { Places } from "./pages/series/black_sun/glossary/Places";
import { Stars } from "./pages/series/black_sun/glossary/Stars";
import { States } from "./pages/series/black_sun/glossary/States";
import { Characters } from "./pages/series/black_sun/glossary/Characters"
import { Space } from "./pages/series/black_sun/glossary/Space"
import { Bird_of_passage_guide_to_milky_way } from "./pages/series/black_sun/glossary/universe/bird_of_passage_guide_to_milky_way";
import { Planets } from "./pages/series/black_sun/glossary/Planets";
import { Gallery_worlds } from "./pages/series/black_sun/gallery/gallery_worlds";
import { Gallery_characters } from "./pages/series/black_sun/gallery/gallery_characters";
import { Gallery_space } from "./pages/series/black_sun/gallery/gallery_space";
import { FloatButton } from 'antd';

class App extends Component {

  render() {
    return (
      <div className="App" style={{ "minHeight": "calc(100vh - 250px - 56px - 54px)" }} >
        <Router>
          <Header />
          <MainMenu />
          <main className="container">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/search" component={Search} />
              <Route exact path={wiki["all_books"].link} component={Books} />
              <Route exact path={wiki["bs_series"].link} component={Series_black_sun} />

              <Route exact path={wiki["book_cydonian_girl"].link} component={BookCydonianGirl} />
              <Route exact path={wiki["book_hyperbeacons"].link} component={BookHyperbeacons} />
              <Route exact path={wiki["book_cydonian_autumn"].link} component={BookCydonianAutumn} />
              <Route exact path={wiki["book_bonecollectors"].link} component={BookBonecollectors} />
              <Route exact path={wiki["book_helleon_cold"].link} component={BookHelleonCold} />
              <Route exact path={wiki["book_tamanrasett_contact"].link} component={BookTamanrasettContact} />
              <Route exact path={wiki["book_the_free_traders"].link} component={BookFreeTraders} />
              <Route exact path={wiki["book_black_sun"].link} component={BookBlackSun} />

              <Route exact path={wiki["bs_tech_anzuritum"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_organizations_union_of_terrformers"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_comp_briglioni_leushoffer"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_space_castanaro_ledge"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_star_mu_holothuria"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_star_schiaparelli"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_star_last_exodus"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_state_tribalon_republic"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_space_arthuri_belt"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_planet_cyrenaic"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_star_leeroy_jenkins"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_space_kyrikia"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_ships_aniraadha"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_ships_locust"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_space_mistral_sector"].link} component={PageInConstruction} />

              <Route exact path={wiki["bs_glossary"].link} component={Glossary} />
              <Route exact path={wiki["bs_wiki_all"].link} component={GlossaryFullList} />
              <Route exact path={wiki["bs_characters"].link} component={Characters} />
              <Route exact path={wiki["bs_space"].link} component={Space} />
              <Route exact path={wiki["bs_planets"].link} component={Planets} />
              <Route exact path={wiki["bs_places"].link} component={Places} />
              <Route exact path={wiki["bs_organizations"].link} component={Organizations} />
              <Route exact path={wiki["bs_weapons_and_tech"].link} component={Weapons_and_tech} />
              <Route exact path={wiki["bs_timeline"].link} component={BlackSunTimelinePage} />
              <Route exact path={wiki["bs_people"].link} component={People} />
              <Route exact path={wiki["bs_universe"].link} component={Universe} />
              <Route exact path={wiki["bs_technologies"].link} component={Tech} />
              <Route exact path={wiki["bs_machinery"].link} component={Machinery} />
              <Route exact path={wiki["bs_creatures"].link} component={Creatures} />
              <Route exact path={wiki["bs_stars"].link} component={Stars} />
              <Route exact path={wiki["bs_states"].link} component={States} />


              <Route exact path={wiki["bs_gallery"].link} component={Gallery} />
              <Route exact path={wiki["bs_gallery_worlds"].link} component={Gallery_worlds} />
              <Route exact path={wiki["bs_gallery_characters"].link} component={Gallery_characters} />
              <Route exact path={wiki["bs_gallery_space"].link} component={Gallery_space} />

              <Route exact path={wiki["bs_maps"].link} component={BlackSunWorldMap} />
              <Route exact path={wiki["bs_maps_oecumene"].link} component={BlackSunWorldMapOecumene} />

              <Route exact path={wiki["bs_unenclosed"].link} component={Unenclosed} />
              <Route exact path={wiki["bs_unenclosed_cydonian_girl_covers"].link} component={PageInConstruction} />
              <Route exact path={wiki["bs_unenclosed_hyperbeacons_editor_comments"].link} component={PageInConstruction} />

              <Route exact path={wiki["bs_timeline_war_300"].link} component={War_300} />
              <Route exact path={wiki["bs_timeline_the_shark_revolt"].link} component={The_shark_revolt} />
              <Route exact path={wiki["bs_timeline_ackron_conspiracy"].link} component={Ackron_conspiracy} />
              <Route exact path={wiki["bs_timeline_tajbennae_incident"].link} component={Tajbennae_incident} />
              <Route exact path={wiki["bs_timeline_light_travel_times"].link} component={Light_travel_times} />
              <Route exact path={wiki["bs_timeline_galactic_war"].link} component={Galactic_war} />

              <Route exact path="/black_sun/glossary/universe/hitchikers_guide_to_milky_way" component={Bird_of_passage_guide_to_milky_way} />

              {/* Old pages redirects */}
              <Route exact path="/black_sun/glossary/space/fleur-sector">
                <Redirect to={wiki["bs_space_fiorella_sector"].link} />
              </Route>
              <Route exact path="/black_sun/glossary/space/ardemar-sector">
                <Redirect to={wiki["bs_space_ardimare_sector"].link} />
              </Route>

              <Route path="/black_sun/glossary/:bs_gl_cat/:art_url" component={BSGlossaryRecord} />

              <Route exact path="/black_sun/books/the_free_traders">
                <Redirect to={wiki["book_the_free_traders"].link} />
              </Route>
              <Route exact path="/black_sun/books/cydonian_girl">
                <Redirect to={wiki["book_cydonian_girl"].link} />
              </Route>
              <Route exact path="/black_sun/books/cydonian_autumn">
                <Redirect to={wiki["book_cydonian_autumn"].link} />
              </Route>
              <Route exact path="/black_sun/books/the_tamanrasett_contact">
                <Redirect to={wiki["book_tamanrasett_contact"].link} />
              </Route>
              <Route exact path="/black_sun/books/the_black_sun">
                <Redirect to={wiki["book_black_sun"].link} />
              </Route>

              <Route exact path="/black_sun/books/helleon_cold">
                <Redirect to={wiki["book_helleon_cold"].link} />
              </Route>

              <Route exact path="/black_sun/books/bonecollectors">
                <Redirect to={wiki["book_bonecollectors"].link} />
              </Route>

              <Route exact path="/black_sun/books/hyperbeacons">
                <Redirect to={wiki["book_hyperbeacons"].link} />
              </Route>

              <Route component={NotFound} path="*" status={404} />
            </Switch>
          </main>
          <Footer />
        </Router>
        <FloatButton.BackTop />
      </div>
    )
  }
}

export default App;