import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function LileosCongestion() {
    return {
        "date": <span>-</span>,
        "type": <span>Territory</span>,
        "owned": <span>none</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_new_havana"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} /></span>,
        "appears":
            <>
            </>,
        "text":
            <>
                <h3 className="title-bg">Brief</h3>

                <p className="card-text text-justify">
                    <span className="strong">Lileos Congestion</span> is a dense and dynamic region in the Sambora sector of the Free Worlds within the Brianne Cluster. Known for its dense field of planets, asteroids, and nebulous zones, it presents an environment where navigating is a skill and a risk all in one. With New Havana as its bustling capital, Lileos Congestion serves as a hub of cultural exchange, trade, and occasional lawlessness—a true reflection of Free Worlds’ spirit of independence and resilience.
                </p>
                <p className="card-text text-justify">
                    The Samborians who inhabit this region have adapted to the chaotic layout of Lileos, forming tightly-knit communities and intricate trade networks. These networks are essential to survive and thrive amidst Lileos’s unpredictable conditions and stand testament to the resourcefulness and independence of its people. The Congestion’s reputation as a free and often wild zone attracts various travelers, from adventurous traders to those simply looking to disappear among the stars.
                </p>
            </>
    }
}
