import { GetQuotes } from "../../../components/Quotes";
import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { ImgRound3EM, ImgRound3EMFancyBoxArticle } from "../../../components/imgs/imgRound";
import { GetConnections, TextInlineLink } from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";

export function BookCydonianAutumn() {
    let repo = "book_cydonian_autumn"
    let name = wiki[repo].name
    document.title = "Cydonian Sun - " + name
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Cydonian autumn</span>} />
            </div>
            <div className="row">
                <ul className="characters-bar">

                </ul>
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox href={imgs["book_cydonian_autumn_jpg_en"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Cover of Cydonian autumn"
                            src={imgs["book_cydonian_autumn_jpg_en"].link} />
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Cydonian autumn</h1></div>
                    <div className="row row-alt">
                        <GetQuotes name={repo} />
                    </div>
                    <div className="row">
                        <div className="alert alert-danger text-justify" role="alert">The book is forthcoming
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="47" aria-valuemin="0"
                                    aria-valuemax="100" style={{ "width": "47%" }}>Progress: 47%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_browdy_adam_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_jasmine_haervany_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"} /></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"></i> <h6>Status:</h6> <strong><span
                                className="text-info">in progress</span></strong></li>
                            <li><i className="far fa-calendar-alt"></i> <h6>Release date:</h6> ~2025</li>
                            <li><i className="far fa-list-alt"></i> <h6>Series:</h6> <TextInlineLink
                                name={"bs_series"} text={"Cydonian sun #3"} /></li>
                            <li><i className="fas fa-angle-double-left"></i> <h6>Previous:</h6> <TextInlineLink
                                name={"book_hyperbeacons"} text={"Beacon thieves"} /></li>
                            <li><i className="fas fa-angle-double-right"></i> <h6>Next:</h6> <TextInlineLink
                                name={"book_bonecollectors"} text={"Bonecollectors"} /></li>
                            <li><i className="fa-solid fa-clock-rotate-left"></i> <h6>Place in <TextInlineLink
                                name={"bs_timeline"} text={"history"} />:</h6> ~11.5 month before <TextInlineLink
                                    name={"book_tamanrasett_contact"} text={"Tamanrasett"} /></li>
                            <li>
                                <i className="fas fa-palette"></i> <h6>Grammar witch:</h6> Valery Likos
                            </li>
                            <li>
                                <i className="fas fa-palette"></i> <h6>Artist:</h6>
                            </li>
                            <li><h6>Related pages:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Annotation</h3>
                    <div className=" well">
                        <p className="text-justify">
                            <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Rosalie"} /> leaves for <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} /> to help <TextInlineLink name={"bs_char_de_karma_ivar"} text={"De Karma's"} /> team track down unknown beings capable of abducting a military fleet and even the population of an entire planet overnight.
                        </p>
                        <p className='text-justify'>
                            Ivar himself is delving deeper into <TextInlineLink name={"bs_char_the_whip"} text={"Whip's"} /> history and intends to enlist his support in the coming conflict with the aliens. The Cydonian does not yet know that the daughter of the Last of the <TextInlineLink name={"bs_spec_firstborns"} text={"Firstborns"} /> attracts enemies far more terrifying than the <TextInlineLink name={"bs_spec_vitruvianus"} text={"four-armed humanoids"} />.
                        </p>
                        <p className='text-justify'>
                            The novel is only worth reading after <TextInlineLink name={"book_hyperbeacons"} text={"Beacon thieves"} />.
                        </p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Gallery</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_1_jpg_full"}
                            small={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_browdy_adam_1_jpg_full"}
                            small={"bs_char_browdy_adam_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_karma_ivar_1_jpg_full"}
                            small={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_1_jpg_full"}
                            small={"bs_char_esora_eve_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_2_jpg_full"}
                            small={"bs_char_esora_eve_2_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_3_jpg_full"}
                            small={"bs_char_esora_eve_3_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_4_jpg_full"}
                            small={"bs_char_esora_eve_4_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_can_beucan_gaereas_1_jpg_full"}
                            small={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_colbert_simone_1_jpg_full"} small={"bs_char_colbert_simone_1_jpg_200"} />
                        </li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_van_gloria_adel_1_jpg_full"} small={"bs_char_van_gloria_adel_1_jpg_200"} />
                        </li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_oliver_1_jpg_full"} small={"bs_char_skarsgard_oliver_1_jpg_200"} />
                        </li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_vivien_1_jpg_full"} small={"bs_char_skarsgard_vivien_1_jpg_200"} />
                        </li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_hartrey_kellays_1_jpg_full"} small={"bs_char_hartrey_kellays_1_jpg_200"} />
                        </li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"} small={"bs_char_the_whip_1_jpg_200"} />
                        </li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_jasmine_haervany_1_jpg_full"} small={"bs_char_jasmine_haervany_1_jpg_200"} />
                        </li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_fa1_jpg_full"} small={"bs_char_de_levine_rosalie_fa1_jpg_200"} />
                        </li>
                        <li>
                            <ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_fa2_jpg_full"} small={"bs_char_de_levine_rosalie_fa2_jpg_200"} />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

