import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function PlanetMarket() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong> <span>galaxy-wide institution</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span>Brokering and regulating transactions for planets and celestial bodies</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Headquarters:</strong>
                        <span><TextInlineLink name={"bs_planet_thalassa"} />, Equénor station</span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Planet Market</span> is a formidable institution, a collective of legal, financial, and diplomatic organizations dedicated to brokering and regulating transactions for planets and celestial bodies across the galaxy. While the idea of purchasing an entire world might seem outlandish to some, for powerful governments, corporations, and affluent individuals, the Planet Market is a gateway to establishing sovereignty, expanding resources, or creating a legacy.
                </p>
                <p className="card-text text-justify">
                    Operating under a complex legal framework, the Planet Market provides clients with comprehensive services, including evaluation, negotiations, transactional security, and settlement processes. Purchasers are required to meet strict environmental, ethical, and legal standards, ensuring the acquisition respects both intergalactic treaties and the rights of indigenous populations or pre-existing settlements. Due to this oversight, the Market has gained a reputation as a stable and reliable authority in celestial real estate.
                </p>
                <p className="card-text text-justify">
                    For many, the Planet Market is a symbol of ultimate power and ambition, opening up not only worlds but also endless opportunities. Its existence reflects the scale and complexity of galactic civilization, where entire worlds can be assets, investments, or the foundations of new futures.
                </p>
                <p className="card-text text-justify">
                    The Planet Market’s headquarters are located on a neutral, politically stable space station, central to major trade routes and diplomatic channels. This location is officially called Cosmopolitan hub of Equénor. It's a secure, highly developed orbital station between Calliara and Old Space, facilitating transactions across the galaxy with ease. Equénor has maintained neutrality in galactic conflicts, with independent security forces ensuring its sanctity as a safe zone.
                </p>
                <p className="card-text text-justify">
                    The Planet Market’s headquarters within Equénor is a sprawling complex known as the Celestial Exchange Pavilion, designed to mirror both opulence and discretion. There, representatives from across the galaxy converge, whether to negotiate a world’s purchase or to facilitate interplanetary investments. The Pavilion houses state-of-the-art negotiation chambers, high-security archives of transaction records, and extensive legal resources for resolving disputes or verifying ownership, all operating under the highest confidentiality.
                </p>
                <p className="card-text text-justify">
                    Equénor is an independent orbital station renowned for its neutrality and stability, orbiting a luminous blue-white star Elaraon. It's known for its steady and long-lived brilliance. Elaraon casts a gentle, warm light across Thalassa, softly illuminating Equénor.
                </p>
                <p className="card-text text-justify">
                    The station is divided into tiers, with the Celestial Exchange Pavilion as its crown jewel, while below it, tiers are dedicated to commerce, hospitality, and residence, creating an almost self-sustaining city. Though an independent space station, Equénor adheres to a strict code of neutrality enforced by its own security forces, and it operates on an open-door policy with regulated entry to maintain order. Travelers from across the galaxy—whether merchants, dignitaries, appreciate Equénor as a neutral ground where business deals, diplomacy, and cultural exchanges can flourish away from outside interference.
                </p>
                <p className="card-text text-justify">
                    Positioned in the sky above the mist-veiled world of Thalassa, Equénor hovers as a gleaming testament to neutrality and quiet power. Named after an ancient word symbolizing “harmony in the unknown,” Equénor encapsulates a spirit of balance amid the boundless expanse.
                </p>
                <p className="card-text text-justify">
                    The city itself is anchored in the sky via anti-gravity generators and solar-sail stabilizers, creating a stationary orbit that allows it to “float” gracefully above Thalassa’s misty clouds. This location not only ensures high security but also symbolizes the station’s neutrality—a world apart, welcoming to all but committed to none.
                </p>
                <p className="card-text text-justify">
                    The Planet Market headquarters are surrounded by embassies, luxury residencies, and trade offices. The endless view over Thalassa’s jade oceans from the pavilion’s crystal walls reinforces its allure as a neutral ground, a tranquil refuge for dignitaries, merchants, and intellectuals seeking the calm required for monumental decisions and negotiations. Sealing a deal here can take weeks, months, or even years, but the results are often worth the wait.
                </p>
                <p className="card-text text-justify">
                    Thalassa herself, while beautiful from above, is uninhabitable due to its volatile lower atmosphere and tectonic instability, lending Equénor an aura of both prestige and inaccessibility.
                </p>
            </>
    }
}
