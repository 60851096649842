import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../components/Quotes";
import { ImgRound3EM, ImgRound3EMFancyBoxArticle } from "../../../components/imgs/imgRound";
import { GetConnections, TextInlineLink } from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";


export function BookCydonianGirl() {
    let repo = "book_cydonian_girl"
    let name = wiki[repo].name
    document.title = "Cydonian Sun - " + name

    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book" />  Cydonian girl</span>} />
            </div>
            <div className="row">
                <ul className="characters-bar">

                </ul>
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_cydonian_girl_jpg_en"].link}>
                        <img className="img-max-full-width img-thumbnail-v2"
                            alt="Cydonian girl book cover"
                            src={imgs["book_cydonian_girl_jpg_en"].link} />
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Cydonian girl</h1></div>
                    <div className="row row-alt">
                        <GetQuotes name={repo} />
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_browdy_adam_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_4_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_montgomery_lorena_1_jpg_200"} /></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li>
                                <i className="fas fa-feather-alt" /> <h6>Status:</h6> <strong><span
                                    className="text-success">published (not translated)</span></strong>
                            </li>
                            <li>
                                <i className="far fa-calendar-alt"></i> <h6>Release date:</h6> 10.03.2021
                            </li>
                            <li>
                                <i className="far fa-list-alt"></i> <h6>Series:</h6> <TextInlineLink
                                    name={"bs_series"} text={"Cydonian sun #1"} popup={""} />
                            </li>
                            <li>
                                <i className="fas fa-angle-double-left"></i> <h6>Previous:</h6> <strong><span
                                    className="text-danger">none</span></strong>
                            </li>
                            <li>
                                <i className="fas fa-angle-double-right"></i> <h6>Next:</h6> <TextInlineLink
                                    name={"book_hyperbeacons"} text={"Beacon thieves"} />
                            </li>
                            <li>
                                <i className="fa-solid fa-clock-rotate-left"></i> <h6>Place in <TextInlineLink
                                    name={"bs_timeline"} text={"history"} />:</h6> ~14 month before <TextInlineLink
                                    name={"book_tamanrasett_contact"} text={"Tamanrasett"} />
                            </li>
                            <li>
                                <i className="fas fa-palette"></i> <h6>Grammar witch:</h6> Valery Likos
                            </li>
                            <li>
                                <i className="fas fa-palette"></i> <h6>Artist:</h6> [REDACTED]
                            </li>
                            <li>
                                <h6>Related pages:</h6><>{GetConnections(repo).map(conn => (conn))}</>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Annotation</h3>
                    <div className=" well">
                        <p className="card-text text-justify">
                            <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar"} />, a disciplined <TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} />, and <TextInlineLink name={"bs_char_esora_eve"} text={"Eve"} />, a determined <TextInlineLink name={"bs_people_agathonians"} text={"Agathonian"} />, find themselves caught in a foreign civil war far from home. Forced to rely on each other to survive, they soon discover that the conflicts between nations are only a prelude to a far greater threat looming over the galaxy.
                        </p>
                        <p className="card-text text-justify">
                            Amid the chaos of <TextInlineLink name={"bs_state_the_great_monarchy"} text={"conflict-ridden country"} />, a chilling mystery unfolds—entire planets are left abandoned, their populations vanishing without a trace. With no prior contact with alien life, humanity is forced to face the unimaginable. Someone, or something, has begun targeting the descendants of <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />.
                        </p>
                        <p className="card-text text-justify">
                            Driven to uncover the truth, Ivar and Eve embark on a dangerous search for answers. But the trail leads them to an enigmatic Cydonian girl who seems to have appeared from nowhere.
                        </p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Gallery</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_1_jpg_full"} small={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_browdy_adam_1_jpg_full"} small={"bs_char_browdy_adam_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_can_beucan_gaereas_1_jpg_full"} small={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_karma_ivar_1_jpg_full"} small={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_1_jpg_full"} small={"bs_char_esora_eve_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"} small={"bs_char_the_whip_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"earth_landscape_1_full"} small={"earth_landscape_1_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"earth_landscape_2_full"} small={"earth_landscape_2_200"} /></li>
                    </ul>
                </div>
            </div>
            <div className="row row-alt title-bg"><h3>Interesting facts</h3></div>
            <div className="row row-alt well">
                <ul className="book-info">
                    <li className="card-text text-justify">
                        <h6>Alternative name</h6>
                        <br />
                        The working name of the book was <i>Odd Child</i>, but at the last minute before publication it was decided to change it to <i>Cydonian girl</i>. The choice was actually a difficult one: the old one seemed like the perfect description of the main character, while the new one helped to kick up the mystery. The mystery won.
                    </li>
                    <li className="card-text text-justify"><h6>Monarch's full name...</h6><br />…sounds like <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Ciel Agastas Corel Horace Alcanarra"} />. I think you can guess why it is not mentioned as such in the story.
                    </li>
                    <li className="card-text text-justify"><h6>Esora the Boss</h6><br />
                        In the original version of the text, Eve had an entire squad of commandos under her command, called the Velimore Cats. But it was cut out for lack of use (as well as the planet Velimore), only a character named Ap was left - we will meet him in Cydonian Autumn.
                    </li>
                    <li className="card-text text-justify"><h6>Eve and Gary narrowly escaped death</h6><br />
                        The original plan was for them to go to the other side of the world at the end of <i>Cydonian girl</i>, but at some point it became clear that they perfectly complement the set of characters and getting rid of them is too early.
                    </li>
                    <li className="card-text text-justify"><h6>Eiliren was Glossa</h6><br />
                        At first, the story had a Glossian Domination instead of the <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Eilirén Domination"} />, with a capital on Glossa. But the name eventually struck me as odd. At the time, Eiliren was a separate state with its own little line and backstory, as well as the home of Valkyrie Löken (read Deleted Heroes). But after the collapse of her storyline it began to weigh on the narrative for no purpose at all. Emergency measures had to be taken
                    </li>
                    <li className="card-text text-justify"><h6>The Scintillian Kingdom</h6><br />
                        <p>
                            Part of the plot involved a planet called Spark (Scintilla from Italian), on which Ivar helped the local queen suppress a people's rebellion in exchange for her help in overthrowing the Monarch. Ironic.
                        </p>
                        <p>
                            The Sparkian or Scintillian Kingdom is a planet-state, a tiny metropolis that barely supports itself with food. It has nothing remarkable on it, but it is located in the middle of the Shark sector, though it is not technically Monarchy territory.
                        </p>
                        <p>
                            The scenes with her were rather boring, and after another read-through I was horrified and deleted them
                        </p>
                    </li>
                    <li className="card-text text-justify"><h6>Ladybug and <TextInlineLink name={"bs_char_browdy_adam"} text={"the Alamarsie"} /></h6><br />
                        My favorite insect is a ladybug (I was once bitten by one - a very strange sensation). In the first version of the scene on <TextInlineLink name={"bs_planet_scellurae"} text={"Scellurae"} />, it was the ladybug that bit Adam. Or rather, a giant copy of it. It was a reflection of my psychological trauma.
                    </li>
                    <li className="card-text text-justify"><h6>Earls</h6><br />The governors of the Monarchy were originally called Earls. But that sounded very pretentious, so I replaced it with a simpler word.
                    </li>
                </ul>
            </div>
        </div>
    )
}

